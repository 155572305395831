import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getToken } from '@/api/qiniu';
import { AttachmentC } from '@/api/ceshi_xiugai/Myteacher';
export default {
  name: 'SingleImageUpload3',
  props: {
    value: {
      type: String,
      default: ''
    },
    editCustomer: {
      type: Number,
      default: 0
    },
    file: {
      type: Array
    }
  },
  data: function data() {
    return {
      tempUrl: '',
      fileList: [],
      fileId: [],
      fileUrl: '',
      dataObj: {
        token: '',
        key: ''
      },
      edit: 0
    };
  },
  mounted: function mounted() {
    var _this = this;
    if (this.file.length > 0) {
      this.$nextTick(function () {
        _this.fileList = _this.file;
      });
    } else {
      this.fileList = [];
    }
  },
  computed: {
    imageUrl: function imageUrl() {
      return this.value;
    }
  },
  methods: {
    setfileList: function setfileList() {
      if (this.value) {
        this.fileList.splice(0, 1, {
          name: this.value
        });
      } else {
        this.fileList.splice(0, 1);
      }
    },
    rmImage: function rmImage(val) {
      // this.emitInput('');
      this.$emit('del-event', val);
    },
    emitInput: function emitInput(val) {
      this.$emit('child-event', val);
    },
    handleExceed: function handleExceed() {
      this.$notify({
        title: '警告',
        message: '最多只能上传5个附件,请删除其他附件后重新上传！',
        type: 'warning',
        duration: 2000
      });
    },
    handleRemove: function handleRemove(file, fileList) {
      this.rmImage(file.id);
      if (this.editCustomer == 1) {
        this.$emit('dbleditcustomer', 'attachment');
      }
    },
    handlePreview: function handlePreview(file) {
      if (file.url) {
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          alert('推荐谷歌进行文件预览');
        } else {
          window.open(process.env.VUE_APP_BASE_API2 + file.url); //blob格式地址
        }
      } else {
        if (!!window.ActiveXObject || 'ActiveXObject' in window) {
          alert('推荐谷歌进行文件预览');
        } else {
          window.open(process.env.VUE_APP_BASE_API2 + this.fileUrl); //blob格式地址
        }
      }
    },
    beforeUpload: function beforeUpload(file) {
      var _this2 = this;
      var tu = file.file.name.split('.')[1];
      if (tu == 'xlsx' || tu == 'doc' || tu == 'docx' || tu == 'pdf' || tu == 'png' || tu == 'jpg' || tu == 'gif') {
        return new Promise(function (resolve, reject) {
          AttachmentC(file).then(function (response) {
            _this2.fileId.push(response.data.id);
            _this2.fileUrl = response.data.path;
            _this2.emitInput(_this2.fileId);
            if (_this2.editCustomer == 1) {
              _this2.$emit('dbleditcustomer', 'attachment');
            }
            resolve(true);
          }).catch(function (err) {
            reject(false);
          });
        });
      } else {
        this.$notify({
          title: '警告',
          message: '请选择正确格式的附件',
          type: 'warning',
          duration: 2000
        });
      }
    }
  }
};