import request from '@/utils/request';
/*-------------------zzy接口---------------------------- */
//班级管理列表zzy
export function ClassManagementIndex(data) {
  return request({
    url: '/clientserver.php/ClassManagement/getClassList',
    method: 'post',
    data: data
  });
}
//新建、编辑班级zzy
export function editClassInfo(data) {
  return request({
    url: '/clientserver.php/ClassManagement/editClassInfo',
    method: 'post',
    data: data
  });
}
//删除班级zzy
export function delClassInfo(data) {
  return request({
    url: '/clientserver.php/ClassManagement/delClassInfo',
    method: 'post',
    data: data
  });
}
//详情班级zzy
export function getClassInfo(data) {
  return request({
    url: '/clientserver.php/ClassManagement/getClassInfo',
    method: 'post',
    data: data
  });
}
//班级院校列表zzy
export function getCollegeList(data) {
  return request({
    url: '/clientserver.php/Common/schoolList',
    method: 'post',
    data: data
  });
}
//班级专业列表zzy
export function getMajorList(data) {
  return request({
    url: '/clientserver.php/Common/majorList',
    method: 'post',
    data: data
  });
}
//班级班主任列表zzy
export function getMainTeacherList(data) {
  return request({
    url: '/clientserver.php/ClassManagement/getMainTeacherList',
    method: 'post',
    data: data
  });
}
//院校管理列表zzy
export function School(data) {
  return request({
    url: '/clientserver.php/School/index',
    method: 'post',
    data: data
  });
}
//院校新增数据zzy
export function SchoolSave(data) {
  return request({
    url: '/clientserver.php/School/save',
    method: 'post',
    data: data
  });
}
//院校获取院校详情zzy
export function SchoolRead(data) {
  return request({
    url: '/clientserver.php/School/read',
    method: 'post',
    data: data
  });
}
//院校编辑数据zzy
export function SchoolUpdate(data) {
  return request({
    url: '/clientserver.php/School/update',
    method: 'post',
    data: data
  });
}
//院校删除数据zzy
export function SchoolDelete(data) {
  return request({
    url: '/clientserver.php/School/delete',
    method: 'post',
    data: data
  });
}
/*-------------------zml接口---------------------------- */
//专业列表zml
export function Majorlist(data) {
  return request({
    url: '/clientserver.php/Major/index',
    method: 'post',
    data: data
  });
}
//专业列表zml新建专业
export function Addmajorlist(data) {
  return request({
    url: '/clientserver.php/Major/save',
    method: 'post',
    data: data
  });
}
export function MajorRead(data) {
  return request({
    url: '/clientserver.php/Major/read',
    method: 'post',
    data: data
  });
}
//专业编辑zml
export function Majorupdate(data) {
  return request({
    url: '/clientserver.php/Major/update',
    method: 'post',
    data: data
  });
}
//专业删除zml
export function Majordel(data) {
  return request({
    url: '/clientserver.php/Major/delete',
    method: 'post',
    data: data
  });
}
//方向列表zml
export function DirectionList(data) {
  return request({
    url: '/clientserver.php/MajorDirection/index',
    method: 'post',
    data: data
  });
}
//方向删除zml
export function Directiondel(data) {
  return request({
    url: '/clientserver.php/MajorDirection/delete',
    method: 'post',
    data: data
  });
}
//方向新建zml
export function DirectionAdd(data) {
  return request({
    url: '/clientserver.php/MajorDirection/save',
    method: 'post',
    data: data
  });
}
//方向编辑zml
export function Directionupdate(data) {
  return request({
    url: '/clientserver.php/MajorDirection/update',
    method: 'post',
    data: data
  });
}
//方向详情zml
export function Directionread(data) {
  return request({
    url: '/clientserver.php/MajorDirection/read',
    method: 'post',
    data: data
  });
}
//方向附件上传zml
export function Directionatta(data) {
  return request({
    url: '/clientserver.php/Common/attachment',
    method: 'post',
    data: data
  });
}

//简章新建zml
export function BrochuresAdd(data) {
  return request({
    url: '/clientserver.php/Brochures/save',
    method: 'post',
    data: data
  });
}
//简章列表zml
export function BrochuresList(data) {
  return request({
    url: '/clientserver.php/Brochures/index',
    method: 'post',
    data: data
  });
}
//简章删除zml
export function Brochuresdel(data) {
  return request({
    url: '/clientserver.php/Brochures/delete',
    method: 'post',
    data: data
  });
}
//简章详情zml
export function Brochuresone(data) {
  return request({
    url: '/clientserver.php/Brochures/read',
    method: 'post',
    data: data
  });
}

//简章附件上传zml
export function Brochuresup(data) {
  return request({
    url: '/clientserver.php/Common/attachment',
    method: 'post',
    data: data
  });
}
//简章编辑zml
export function Brochuresupdate(data) {
  return request({
    url: '/clientserver.php/Brochures/update',
    method: 'post',
    data: data
  });
}
/*-------------------接收客户信息---------------------------- */
// 列表
export function CustomerReceiveList(data) {
  return request({
    url: '/clientserver.php/CustomerReceive/list',
    method: 'post',
    data: data
  });
}

/*----------------产品数据列表批量发布下架--------------------*/
export function productBatchEditStatus(data) {
  return request({
    url: '/clientserver.php/Product/batchEditStatus',
    method: 'post',
    data: data
  });
}
// 产品分页列表
export function getProductPageList(data) {
  return request({
    url: '/clientserver.php/Product/getProductPageList',
    method: 'post',
    data: data
  });
}