var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "el-upload",
        {
          staticClass: "upload-demo",
          attrs: {
            type: "file",
            data: _vm.dataObj,
            "on-exceed": _vm.handleExceed,
            "http-request": _vm.beforeUpload,
            "on-remove": _vm.handleRemove,
            "on-preview": _vm.handlePreview,
            "file-list": _vm.fileList,
            limit: 5,
            action:
              "https://gewawe.yangongzi.cn/clientserver.php/Common/attachment",
          },
        },
        [
          _c(
            "el-button",
            { staticStyle: { float: "left" }, attrs: { type: "primary" } },
            [
              _vm._v("上传"),
              _c("i", { staticClass: "el-icon-upload el-icon--right" }),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }